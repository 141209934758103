import React, { Component } from "react";
import { API } from "aws-amplify";
import { v4 as uuid } from "uuid";
import List from "../../ui/List";
import "./style.scss";
import LoggedOutHome from "./LoggedOutHome";
import { DragDropContext } from "react-beautiful-dnd";

export default class Home extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      tasks: [],
      addingTask: false,
      noData: true
    };
  }

  async componentDidMount() {
    let statefulStuff = {
      noData: true,
      isLoading: true,
      tasks: []
    };

    if (
      this.props.currentAuthUser !== undefined &&
      this.props.currentAuthUser !== null
    ) {
      try {
        statefulStuff.tasks = await API.get("stack", "/getTasks");
        statefulStuff.tasks = this.sortList(statefulStuff.tasks.Items);
      } catch (e) {
        console.error(e);
      }
    }

    statefulStuff.isLoading = false;
    this.setState(statefulStuff);
  }

  sortList = (tasks) => {
    try {
      tasks.sort((firstEl, secondEl) => {
        if (firstEl.taskPosition < secondEl.taskPosition) {
          return -1;
        }
        if (firstEl.taskPosition > secondEl.taskPosition) {
          return 1;
        }
        return 0;
      });

      return tasks;
    } catch (e) {
      console.error(e);
    }
  }

  addTask = () => {
    this.props.history.push(`/new/task`);
  };

  editTask = taskId => {
    this.props.history.push(`/edit/task/${taskId}`);
  };

  renderAllTasks = () => {
    let allLists = [];
    allLists.push(
      <List
        key={uuid()}
        tasks={this.state.tasks.slice(0, 1)}
        tasksList={this.state.tasks}
        addTask={this.addTask}
        editTask={this.editTask}
        removeTask={this.removeTask}
        finishTask={this.finishTask}
        moveTaskUp={this.moveTaskUp}
        moveTaskDown={this.moveTaskDown}
        moveTaskPosition={this.moveTaskPosition}
        refreshList={this.refreshList}
        title=""
        firstList={true}
        secondList={false}
      />
    );
    allLists.push(
      <List
        key={uuid()}
        tasks={this.state.tasks.slice(1, 2)}
        tasksList={this.state.tasks}
        addTask={this.addTask}
        editTask={this.editTask}
        removeTask={this.removeTask}
        finishTask={this.finishTask}
        moveTaskUp={this.moveTaskUp}
        moveTaskDown={this.moveTaskDown}
        moveTaskPosition={this.moveTaskPosition}
        refreshList={this.refreshList}
        title="Next up"
        firstList={false}
        secondList={true}
      />
    );
    allLists.push(
      <List
        key={uuid()}
        tasks={this.state.tasks.slice(2)}
        tasksList={this.state.tasks}
        addTask={this.addTask}
        editTask={this.editTask}
        removeTask={this.removeTask}
        finishTask={this.finishTask}
        moveTaskUp={this.moveTaskUp}
        moveTaskDown={this.moveTaskDown}
        moveTaskPosition={this.moveTaskPosition}
        refreshList={this.refreshList}
        title="Later"
        firstList={false}
        secondList={false}
      />
    );
    return allLists;
  };

  renderTasks = () => {
    return (
      <div>
        {!this.state.isLoading && this.renderAllTasks()}
      </div>
    );
  };

  finishTask = taskId => {
    try {
      let bodyParams = {
        taskId: taskId,
      };

      API.post("stack", "/finishTask", {
        body: bodyParams
      });
    } catch (e) {
      this.setState({ isLoading: false });
    }
  }

  refreshList = taskId => {
    // make a copy of tasks
    let tasksCopy = this.state.tasks.slice(0);

    // find the index of the task we are removing
    let taskIndex = tasksCopy.findIndex(task => {
      return task.taskId === taskId;
    });

    // get the items after the removal point
    let copyRest = tasksCopy.slice(taskIndex + 1);

    // remove all of the items from and after the item we are removing
    tasksCopy.length = taskIndex;

    // push in all of the items that we copied after the item being removed
    copyRest.forEach(item => {
      tasksCopy.push(item);
    });

    // update the taskPosition of each item based on it's position in the array
    let applyIndex = (item, index) => {
      item.taskPosition = index;
    };

    tasksCopy.map(applyIndex);

    this.setState({ tasks: tasksCopy });
  }

  removeTask = taskId => {
    if (window.confirm("Delete the task?")) {
      let bodyParams = {
        taskId: taskId
      };

      API.del("stack", "/removeTask", {
        body: bodyParams
      });
    }
  };

  moveTask = async moveData => {
    // call API to update the tasks order
    try {
      let bodyParams = {
        taskId: moveData.taskId,
        newPosition: moveData.newPosition
      };

      await API.post("stack", "/moveTask", {
        body: bodyParams
      });
    } catch (e) {
      console.error(e);
    }
  };

  moveTaskUp = (task) => {
    // get list of tasks from state, and update props with new position
    this.persistState({
      newPosition: 0,
      startPosition: task.position
    });

    // THEN call API to persist the move in the DB
    this.moveTask({
      taskId: task.taskId,
      newPosition: 0
    });
  }

  moveTaskDown = (task) => {
    // get list of tasks from state, and update props with new position
    this.persistState({
      newPosition: this.state.tasks.length - 1,
      startPosition: task.position
    });

    // THEN call API to persist the move in the DB
    this.moveTask({
      taskId: task.taskId,
      newPosition: this.state.tasks.length - 1
    });
  }

  moveTaskPosition = (task, newPosition) => {
    // get list of tasks from state, and update props with new position
    this.persistState({
      newPosition: newPosition,
      startPosition: task.position
    });

    // THEN call API to persist the move in the DB
    this.moveTask({
      taskId: task.taskId,
      newPosition: newPosition
    });
  }

  persistState = (moveData) => {
    let tasksCopy = this.state.tasks.slice(0);

    let movedTask = tasksCopy.splice(moveData.startPosition, 1);
    tasksCopy.splice(moveData.newPosition, 0, movedTask[0]);

    tasksCopy.forEach((task, index) => {
      task.taskPosition = index;
    });

    this.setState({ tasks: tasksCopy });
  }

  onDragEnd = result => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (
      destination.index === source.index
    ) {
      return;
    }

    // get list of tasks from state, and update props with new position
    this.persistState({
      newPosition: destination.index,
      startPosition: source.index
    });

    // THEN call API to persist the move in the DB
    this.moveTask({
      taskId: draggableId,
      newPosition: destination.index
    });
  };

  render() {
    let content;

    if (
      this.props.currentAuthUser === undefined ||
      this.props.currentAuthUser === null
    ) {
      content = <LoggedOutHome />;
    } else {
      content = this.renderTasks();
    }

    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <div className="top-margin"></div>
        <div className="c-list__header-container">
          <div className="c-list__header">
            <h6 className="c-list__header__title">The next thing to accomplish</h6>
            <button
              alt="add task"
              className="c-list__header__add-task"
              onClick={this.addTask}
            >Add task</button>
          </div>
        </div>
        <div>{this.state.isLoading ? <div>loading...</div> : content}</div>
      </DragDropContext>
    );
  }
}
