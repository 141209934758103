import React from "react";
import ContentGrid from "../../layout/Content/ContentGrid";
import ContentGridRow from "../../layout/Content/ContentGridRow";
import MainGrid from "../../layout/Main/MainGrid";

export default class LoggedOutHome extends React.Component {
  render() {
    window.scrollTo(0, 0);
    return (
      <MainGrid>
        <ContentGrid>
          <ContentGridRow>
            <h1>One thing at a time</h1>
          </ContentGridRow>
          <ContentGridRow>
            <p>
              Keep it simple, one item at a time.
            </p>
          </ContentGridRow>
        </ContentGrid>
      </MainGrid>
    );
  }
}
