import React from "react";
import MainGrid from "../layout/Main/MainGrid";
import ContentGrid from "../layout/Content/ContentGrid";
import ContentGridRow from "../layout/Content/ContentGridRow";

export default class Settings extends React.Component {
  componentDidMount() {
    if (!this.props.isAuthenticated) {
      this.props.history.push("/login");
    }
  }

  render() {
    let content;

    content = (
      <MainGrid {...this.props}>
        <ContentGrid {...this.props}>
          <ContentGridRow {...this.props}>
            <h2>Settings</h2>
          </ContentGridRow>
          <ContentGridRow {...this.props}>
            <h3 style={{ fontWeight: 400 }}>
              Want to delete your WeekStack account?
            </h3>
            Email{" "}
            <a href="mailto:christopher@weekstack.com">
              christopher@weekstack.com
            </a>{" "}
            and I will take care of deleting your account right away.
          </ContentGridRow>
        </ContentGrid>
      </MainGrid>
    );

    return content;
  }
}
