import React from "react";
import "./LoaderButton.css";
import logo from "./ws-logo.svg";

const LoaderButton = ({
  isLoading,
  text,
  loadingText,
  className = "",
  disabled = false,
  ...props
}) => (
  <button
    className={`LoaderButton ${className}`}
    disabled={disabled || isLoading}
    {...props}
  >
    {isLoading && <img src={logo} alt="loader" className="spinning" />}
    {!isLoading ? text : loadingText}
  </button>
);

export default LoaderButton;