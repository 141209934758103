import React, { Component } from "react";
import { Auth } from "aws-amplify";
import LoaderButton from "../../components/LoaderButton";
import ContentGrid from "../../layout/Content/ContentGrid";
import ContentGridRow from "../../layout/Content/ContentGridRow";
import MainGrid from "../../layout/Main/MainGrid";
import "./style.scss";

const ErrorCodes = [
  "Please log in to proceed",
  "There is already a user with that email address",
  "You need to confirm your email address by clicking the link from WeekStack in your email before logging in",
  "Incorrect password",
  "There is no user with that email address"
];

export default class Login extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      email: "",
      password: ""
    };
  }

  async componentDidMount() {
    if (this.props.isAuthenticated) {
      this.props.history.push("/home");
    }
  }

  validateForm() {
    return this.state.email.length > 0 && this.state.password.length > 0;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });

    try {
      await Auth.signIn(this.state.email, this.state.password);
      this.props.userHasAuthenticated(true);
      this.props.history.push("/home");
    } catch (e) {
      if (e.code === "UserNotConfirmedException") {
        // The error happens if the user didn't finish the confirmation step when signing up
        // In this case you need to resend the code and confirm the user
        // About how to resend the code and confirm the user, please check the signUp part
        this.props.history.push("/login/error/2");
      } else if (e.code === "PasswordResetRequiredException") {
        // The error happens when the password is reset in the Cognito console
        // In this case you need to call forgotPassword to reset the password
        // Please check the Forgot Password part.
      } else if (e.code === "NotAuthorizedException") {
        // The error happens when the incorrect password is provided
        this.props.history.push("/login/error/3");
      } else if (e.code === "UserNotFoundException") {
        // The error happens when the supplied username/email does not exist in the Cognito user pool
        this.props.history.push("/login/error/4");
      } else {
        // display a general error message
        this.props.history.push("/login/error/0");
        console.info(e);
      }
      this.setState({ isLoading: false });
    }
  };

  render() {
    return (
      <MainGrid>
        <ContentGrid>
          <ContentGridRow>
            <h3 style={{ paddingLeft: "8px" }}>Login to WeekStack</h3>
            <div>
              <form onSubmit={this.handleSubmit}>
                <div className="form-grid">
                  {this.props.match.params.error !== undefined && (
                    <label className="error-message">
                      {ErrorCodes[parseInt(this.props.match.params.error)]}
                    </label>
                  )}
                  <div className="form-lockup">
                    <label>Email</label>
                    <input
                      autoFocus
                      id="email"
                      type="email"
                      value={this.state.email}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="form-lockup">
                    <label>Password</label>
                    <input
                      id="password"
                      value={this.state.password}
                      onChange={this.handleChange}
                      type="password"
                    />
                  </div>
                  <LoaderButton
                    disabled={!this.validateForm()}
                    isLoading={this.state.isLoading}
                    text="Login"
                    loadingText="Logging in…"
                  />
                </div>
              </form>
            </div>
          </ContentGridRow>
        </ContentGrid>
      </MainGrid>
    );
  }
}
