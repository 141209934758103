import React from "react";
import { API } from "aws-amplify";
import MainGrid from "../../layout/Main/MainGrid";
import ContentGrid from "../../layout/Content/ContentGrid";
import ContentGridRow from "../../layout/Content/ContentGridRow";
import LoaderButton from "../../components/LoaderButton";
import { v4 as uuid } from "uuid";
import { moveTask } from "../../data/DataManager";

export default class EditTask extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      taskId: "",
      taskName: "",
      taskDescription: "",
      taskPosition: 0,
      taskStartPosition: 0,
      taskCount: 0
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleSelectChange = this.handleSelectChange.bind(this);
  }

  handleSelectChange = event => {
    this.setState({ taskPosition: event.target.value });
  };

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      this.props.history.push("/home");
    }

    let taskCount = await API.get("stack", "/getTasks");
    taskCount = taskCount.Items.length;

    await API.post("stack", "/getTask", {
      body: {
        taskId: this.props.match.params.taskId
      }
    }).then(taskResult => {
      this.setState({
        taskId: taskResult.Items[0].taskId,
        taskName: taskResult.Items[0].taskName,
        taskDescription: taskResult.Items[0].taskDescription,
        taskPosition: taskResult.Items[0].taskPosition,
        taskStartPosition: taskResult.Items[0].taskPosition,
        taskCount: taskCount
      });
    });
  }

  validateForm() {
    return this.state.taskName.length > 0;
  }

  handleSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });

    let task = {
      taskId: this.state.taskId,
      taskName: this.state.taskName,
      taskDescription: this.state.taskDescription
    }

    try {
      await moveTask(task, this.state.taskPosition);
      this.props.history.push("/home");
    } catch (e) {
      this.setState({ isLoading: false });
    }
  };

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  render() {
    return (
      <MainGrid>
        <ContentGrid>
          <ContentGridRow>
            <h2>Edit Task</h2>
          </ContentGridRow>
          <form onSubmit={this.handleSubmit}>
            <ContentGridRow>
              <div className="form-grid">
                <div className="form-lockup">
                  <label>Task name</label>
                  <input
                    autoFocus
                    id="taskName"
                    type="text"
                    value={this.state.taskName}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="form-lockup">
                  <label>Task description</label>
                  <input
                    id="taskDescription"
                    value={this.state.taskDescription}
                    onChange={this.handleChange}
                    type="text"
                  />
                </div>
                <div className="form-lockup">
                  <label>Task position</label>
                  <TaskPosition
                    taskCount={this.state.taskCount}
                    taskPosition={this.state.taskPosition}
                    changeEvent={this.handleSelectChange}
                  />
                </div>
                <LoaderButton
                  className="edit-task-button"
                  disabled={!this.validateForm()}
                  isLoading={this.state.isLoading}
                  text="Save Task"
                  loadingText="Saving task..."
                />
              </div>
            </ContentGridRow>
          </form>
        </ContentGrid>
      </MainGrid>
    );
  }
}

let TaskPosition = props => {
  let options = [];

  for (let x = 1; x <= props.taskCount; x++) {
    options.push(
      <option key={uuid()} value={x - 1}>
        {x}
      </option>
    );
  }

  return (
    <select value={props.taskPosition} onChange={props.changeEvent}>
      {options}
    </select>
  );
};
