import React from "react";
import "./style.scss";
import { Link } from "react-router-dom";
import { Auth } from "aws-amplify";

export default class MainGrid extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      notAuthorized: true,
      userId: "",
      userEmail: "",
      isLoading: true
    };
  }

  async componentDidMount() {
    let statefulStuff;

    try {
      let currUser = await Auth.currentUserInfo();

      statefulStuff = {
        userId: currUser.id,
        userEmail: currUser.attributes.email,
        notAuthorized: false
      };
    } catch (e) {
      if (e !== "No current user") {
        statefulStuff = { notAuthorized: true };
      }
    }

    statefulStuff.isLoading = false;

    this.setState(statefulStuff);
  }

  render() {
    let nav;

    let style = {
      display: this.props.hideNav ? "none" : "block"
    };

    if (this.state.notAuthorized) {
      nav = (
        <div className="main-grid">
          <div className="left-column">
            <Link to="/">
              <img src="/weekstack-logo.svg" alt="logo" />
            </Link>
            <ul>
              <li>
                <Link to="/login">Log in</Link>
              </li>
            </ul>
          </div>
          {this.props.children}
          <div className="right-column" />
        </div>
      );
    } else {
      nav = (
        <div
          className="main-grid"
          style={{ margin: this.props.hideNav ? "32px" : "11rem 0 0 0 " }}
        >
          <div className="left-column" style={style}>
            <Link to="/">
              <img src="/weekstack-logo.svg" alt="logo" />
            </Link>
            <ul>
              <li>
                <Link to="/home">Home</Link>
              </li>
              <li>
                <Link to="/logout">Logout</Link>
              </li>
              <li>
                <Link to="/settings">Settings</Link>
              </li>
              <li>
                <Link to="/help">Help</Link>
              </li>
            </ul>
          </div>
          {this.props.children}
          <div className="right-column" />
        </div>
      );
    }
    return nav;
  }
}
