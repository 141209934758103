import React from "react";

export default class Privacy extends React.Component {
  render() {
    return (
      <React.Fragment>
        <h1>WeekStack Privacy Policy</h1>
        <p>
          The privacy of your data is a big deal to us. We’ll only ever access
          your account to help you with a problem or fix a software bug.
        </p>
        <h2>Identity & access</h2>
        <p>
          When you sign up for WeekStack, we ask for your email address. That’s
          just so you can personalize your new account, and we can send you
          invoices, updates, or other essential information. We’ll never sell
          your personal info to third parties, and we won’t use your name in
          marketing statements without your permission, either.
        </p>
        <p>
          When you write WeekStack with a question or to ask for help, we’ll
          keep that correspondence, and the email address, for future reference.
          When you browse our marketing pages, we’ll track that for statistical
          purposes (like conversion rates and to test new designs). We also
          store any information you volunteer, like surveys, for as long as it
          makes sense.
        </p>
        <p>The only times we’ll ever share your info:</p>
        <ul>
          <li>
            To provide products or services you’ve requested, with your
            permission.
          </li>
          <li>
            To investigate, prevent, or take action regarding illegal
            activities, suspected fraud, situations involving potential threats
            to the physical safety of any person, violations of our Terms of
            Service, or as otherwise required by law.
          </li>
          <li>
            If WeekStack is acquired by or merged with another company — we
            don’t plan on that, but if it happens — we’ll notify you well before
            any info about you is transferred and becomes subject to a different
            privacy policy.
          </li>
        </ul>
        <h2>Law enforcement</h2>
        <p>
          WeekStack won’t hand your data over to law enforcement unless a court
          order says we have to. We flat-out reject requests from local and
          federal law enforcement when they seek data without a court order. And
          unless we’re legally prevented from it, we’ll always inform you when
          such requests are made.
        </p>
        <h2>Encryption</h2>
        <p>
          All data is encrypted via SSL/TLS when transmitted from our servers to
          your browser. Data isn’t encrypted while it’s live in our database
          (since it needs to be ready to send to you when you need it).
        </p>
        <h2>Deleted data</h2>
        When you delete data on WeekStack, it's permanently destroyed.
        <h2>Changes & questions</h2>
        <p>
          WeekStack may update this policy once in a blue moon — we’ll notify
          you about significant changes by emailing the account owner or by
          placing a prominent notice on our site. You can access, change or
          delete your personal information at any time by contacting our support
          team.
        </p>
      </React.Fragment>
    );
  }
}
