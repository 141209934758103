const dev = {
  stackApiGateway: {
    REGION: "us-east-1",
    URL: "https://uonatlfn61.execute-api.us-east-1.amazonaws.com/dev/stack"
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_SbNxtgEyd",
    APP_CLIENT_ID: "37jk4nbpu1f5vvsvomtu0m904v",
    IDENTITY_POOL_ID: "us-east-1:bbf0fc5d-8eb9-4ccd-acf5-f06c6f1b16b1"
  }
};

const prod = {
  stackApiGateway: {
    REGION: "us-east-1",
    URL: "https://6xvb6652c0.execute-api.us-east-1.amazonaws.com/prod/stack"
  },
  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_R9nHis4kK",
    APP_CLIENT_ID: "20dfmsua9ap5qofh43jo513t6k",
    IDENTITY_POOL_ID: "us-east-1:9a2f4e79-a7f5-4d07-83ea-2b6df1fb38b7"
  }
};

const config = {
  ...(process.env.REACT_APP_STAGE === "prod" ? prod : dev),
};

export default config;