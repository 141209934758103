import React, { Component } from "react";
import "./app.scss";
import Routes from "./Routes";
import { withRouter } from "react-router-dom";
import { Auth, API } from "aws-amplify";

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isAuthenticated: false,
      isAuthenticating: true,
      currentAuthUser: null,
      userProfile: null
    };
  }

  componentDidMount() {
    this.grabCredentials();
  }

  grabCredentials = async () => {
    let currentAuthUser, userProfile;
    let newState = {
      userProfile: null,
      currentAuthUser: null,
      isAuthenticating: false,
      isAuthenticated: false
    };

    try {
      currentAuthUser = await Auth.currentAuthenticatedUser();
      newState.isAuthenticated = true;
      userProfile = await API.post("stack", "/getUserProfile", {
        body: {
          userEmail: currentAuthUser.attributes.email
        }
      });
      userProfile = userProfile[0];
    } catch (e) { }

    newState.userProfile = userProfile;
    newState.currentAuthUser = currentAuthUser;

    this.setState(newState);
  };

  userHasAuthenticated = authenticated => {
    if (authenticated) {
      this.setState({ isAuthenticating: true });
      this.grabCredentials();
    } else {
      this.setState({
        isAuthenticated: false,
        userProfile: null,
        currentAuthUser: null
      });
    }
  };

  refreshUserData = async () => {
    let userProfile = await API.post("stack", "/getUserProfile", {
      body: {
        userEmail: this.state.currentAuthUser.attributes.email
      }
    });
    userProfile = userProfile[0];
    this.setState({ userProfile: userProfile });
  };

  render() {
    const childProps = {
      isAuthenticated: this.state.isAuthenticated,
      currentAuthUser: this.state.currentAuthUser,
      userProfile: this.state.userProfile,
      userHasAuthenticated: this.userHasAuthenticated,
      refreshUserData: this.refreshUserData
    };

    return (
      !this.state.isAuthenticating && (
        <div className="App">
          <Routes childProps={childProps} />
        </div>
      )
    );
  }
}

export default withRouter(App);
