import React from "react";
import MainGrid from "../layout/Main/MainGrid";
import ContentGrid from "../layout/Content/ContentGrid";
import ContentGridRow from "../layout/Content/ContentGridRow";

export default class Help extends React.Component {
  render() {
    return (
      <MainGrid>
        <ContentGrid>
          <ContentGridRow>
            <h3>Help is here</h3>
            <p>
              Hey there, this is{" "}
              <a
                href="https://christopherandersson.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Christopher Andersson
              </a>{" "}
              the creator of WeekStack. WeekStack is a really new concept, and
              you are using a very early version of the product. Thanks for
              being a part of this{" "}
              <span role="img" aria-label="face with stars for eyes">
                🤩
              </span>
            </p>
            <h4>Want to chat with me directly?</h4>
            <p>
              I am happy to help in any way that I can. If the help here is not
              enough you can get in touch with me in any of the following ways
            </p>
            <ul>
              <li>
                Tweet at me{" "}
                <a href="https://twitter.com/chrisanderss0n">@chrisanderss0n</a>
              </li>
              <li>
                Email me at{" "}
                <a href="mailto:christopher@weekstack.com">
                  christopher@weekstack.com
                </a>
              </li>
              <li>
                Schedule time to screen share with me{" "}
                <a href="https://calendly.com/weekstack">here</a>
              </li>
            </ul>
          </ContentGridRow>
        </ContentGrid>
      </MainGrid>
    );
  }
}
