import React, { Component } from "react";
import Task from "../Task";
import { Droppable } from "react-beautiful-dnd";
import { v4 as uuid } from "uuid";
import "./style.scss";

export default class List extends Component {
  constructor(props) {
    super(props);

    this.state = {
      tasks: [],
      startTask: false,
    };
  }

  async componentDidMount() {
    try {
      let fetchedList = this.props.tasks;
      fetchedList.sort((firstEl, secondEl) => {
        if (firstEl.taskPosition < secondEl.taskPosition) {
          return -1;
        }
        if (firstEl.taskPosition > secondEl.taskPosition) {
          return 1;
        }
        return 0;
      });
      let createdTasks = this.createTasks(fetchedList);

      this.setState({
        tasks: createdTasks
      });
    } catch (e) {
      console.error(e);
    }
  }

  editTask = taskId => {
    this.props.editTask(taskId);
  };

  finishTask = taskId => {
    this.props.finishTask(taskId);
  }

  removeTask = taskId => {
    this.props.removeTask(taskId);
  };

  moveTaskUp = (task) => {
    this.props.moveTaskUp(task);
  }

  moveTaskDown = (task) => {
    this.props.moveTaskDown(task);
  }

  moveTaskPosition = (task, newPosition) => {
    this.props.moveTaskPosition(task, newPosition);
  }

  refreshList = taskId => {
    this.props.refreshList(taskId);
  }

  createTasks = tasks => {
    let createdTasks = tasks.map(task => {
      return (
        <Task
          key={task.taskId}
          taskId={task.taskId}
          name={task.taskName}
          description={task.taskDescription ? task.taskDescription : ""}
          removeTask={this.removeTask}
          finishTask={this.finishTask}
          moveTaskUp={this.moveTaskUp}
          moveTaskDown={this.moveTaskDown}
          moveTaskPosition={this.moveTaskPosition}
          editTask={this.editTask}
          position={task.taskPosition}
          refreshList={() => { this.refreshList(task.taskId) }}
          tasksList={this.props.tasksList}
        />
      );
    });

    return createdTasks;
  };

  render() {
    let classNames = "c-list";

    if (this.props.firstList) {
      classNames += " firstList";
      return (
        <Droppable droppableId={uuid()}>
          {(provided, snapshot) => (
            <React.Fragment>
              <div
                className={classNames}
                style={{ borderStyle: "solid", borderWidth: "2px", borderColor: snapshot.isDraggingOver ? 'hsla(284, 69%, 56%, 1)' : 'hsla(0, 0%, 14%, 1)' }}
                ref={provided.innerRef} {...provided.droppableProps}
              >
                <h6 className="c-list__header__title">{this.props.title}</h6>
                {this.state.tasks}
                {provided.placeholder}
              </div>
            </React.Fragment>
          )}
        </Droppable>
      );
    }

    if (this.props.secondList) {
      classNames += " secondList";
      return (
        <Droppable droppableId={uuid()}>
          {(provided, snapshot) => (
            <React.Fragment>
              <div
                className={classNames}
                style={{ borderStyle: "solid", borderWidth: "2px", borderColor: snapshot.isDraggingOver ? 'hsla(284, 69%, 56%, 1)' : 'hsla(0, 0%, 14%, 1)' }}
                ref={provided.innerRef} {...provided.droppableProps}
              >
                <h6 className="c-list__header__title">{this.props.title}</h6>
                {this.state.tasks}
                {provided.placeholder}
              </div>
            </React.Fragment>
          )}
        </Droppable>
      );
    }

    return (
      <Droppable droppableId={uuid()}>
        {(provided, snapshot) => (
          <React.Fragment>
            <div
              className={classNames}
              ref={provided.innerRef} {...provided.droppableProps}
            >
              <h6 className="c-list__header__title">{this.props.title}</h6>
              {this.state.tasks}
              {provided.placeholder}
            </div>
          </React.Fragment>
        )}
      </Droppable>
    );
  }
}
