import React from "react";
import { Route, Switch } from "react-router-dom";
import AppliedRoute from "./components/AppliedRoute";
import Home from "./containers/Home";
import Login from "./containers/Login";
import NotFound from "./containers/NotFound";
import CompleteSignUp from "./containers/CompleteSignUp";
import Sad from "./containers/Sad";
import Logout from "./containers/Logout";
import HowItWorks from "./containers/HowItWorks";
import Settings from "./containers/Settings";
import Help from "./containers/Help";
import NewTask from "./containers/NewTask";
import EditTask from "./containers/EditTask";
import Privacy from "./containers/Privacy";
import ControlRoom from "./containers/ControlRoom";

const Routes = ({ childProps }) => (
  <Switch>
    <AppliedRoute path="/" exact component={Home} props={childProps} />
    <AppliedRoute path="/home" exact component={Home} props={childProps} />
    <AppliedRoute
      path="/howitworks"
      exact
      component={HowItWorks}
      props={childProps}
    />
    <AppliedRoute
      path="/settings"
      exact
      component={Settings}
      props={childProps}
    />
    <AppliedRoute
      path="/privacy"
      exact
      component={Privacy}
      props={childProps}
    />
    <AppliedRoute path="/help" exact component={Help} props={childProps} />
    <AppliedRoute path="/login" exact component={Login} props={childProps} />
    <AppliedRoute
      path="/login/error/:error"
      exact
      component={Login}
      props={childProps}
    />
    <AppliedRoute
      path="/nextstep/:email/:code/:temppw"
      exact
      component={CompleteSignUp}
      props={childProps}
    />
    <AppliedRoute path="/sad" exact component={Sad} props={childProps} />
    <AppliedRoute path="/logout" exact component={Logout} props={childProps} />
    <AppliedRoute
      path="/new/task"
      exact
      component={NewTask}
      props={childProps}
    />
    <AppliedRoute
      path="/edit/task/:taskId"
      exact
      component={EditTask}
      props={childProps}
    />
    <AppliedRoute
      path="/control-room"
      exact
      component={ControlRoom}
      props={childProps}
    />
    {/* Finally, catch all unmatched routes */}
    <Route component={NotFound} />
  </Switch>
);

export default Routes;