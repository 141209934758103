import React from "react";
import { Auth, API } from "aws-amplify";
import LoaderButton from "../../components/LoaderButton";
import ContentGrid from "../../layout/Content/ContentGrid";
import ContentGridRow from "../../layout/Content/ContentGridRow";
import MainGrid from "../../layout/Main/MainGrid";

export default class CompleteSignUp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      confirmed: false,
      email: "",
      password: ""
    };
  }

  async componentDidMount() {
    this.setState({ isLoading: true });

    try {
      await Auth.confirmSignUp(
        this.props.match.params.email,
        this.props.match.params.code
      );

      this.setState({
        isLoading: false,
        confirmed: true,
        email: this.props.match.params.email
      });
    } catch (e) {
      this.setState({ isLoading: false });
    }
  }

  validateForm() {
    return this.state.email.length > 0 && this.state.password.length > 0;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });

    try {
      await Auth.signIn(this.state.email, this.props.match.params.temppw);
      await Auth.currentAuthenticatedUser().then(user => {
        return Auth.changePassword(user, this.props.match.params.temppw, this.state.password);
      })
        .then(data => console.log(data))
        .catch(err => console.log(err));

      await API.post("stack", "/createNewUser", {
        body: {
          userEmail: this.state.email
        }
      });

      this.props.userHasAuthenticated(true);

      this.props.history.push("/home");
    } catch (e) {
      console.error(e);
      this.setState({ isLoading: false });
    }
  };

  render() {
    let content = (
      <MainGrid>
        <ContentGrid>
          <ContentGridRow>
            <h2>
              Nice! Now, setup a password.
            </h2>
          </ContentGridRow>
          <ContentGridRow>
            <div>
              <form onSubmit={this.handleSubmit}>
                <div className="form-grid">
                  <div className="form-lockup">
                    <label>Email</label>
                    <input
                      autoFocus
                      id="email"
                      type="email"
                      value={this.state.email}
                      onChange={this.handleChange}
                    />
                  </div>
                  <div className="form-lockup">
                    <label>Password</label>
                    <input
                      id="password"
                      value={this.state.password}
                      onChange={this.handleChange}
                      type="password"
                    />
                  </div>
                  <LoaderButton
                    disabled={!this.validateForm()}
                    isLoading={this.state.isLoading}
                    text="Login"
                    loadingText="Logging in…"
                  />
                </div>
              </form>
            </div>
          </ContentGridRow>
        </ContentGrid>
      </MainGrid>
    );
    return (
      <div>
        {this.state.isLoading ? <div>confirming your account...</div> : content}
      </div>
    );
  }
}
