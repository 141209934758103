import React from "react";
import MainGrid from "../../layout/Main/MainGrid";
import ContentGrid from "../../layout/Content/ContentGrid";
import ContentGridRow from "../../layout/Content/ContentGridRow";
import LoaderButton from "../../components/LoaderButton";
import { v4 as uuid } from "uuid";
import { Auth } from "aws-amplify";

export default class ControlRoom extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      inviteEmail: ""
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      this.props.history.push("/home");
      return;
    }
    if (!this.props.userProfile.majorTom) {
      this.props.history.push("/home");
      return;
    }
  }

  validateForm() {
    return this.state.inviteEmail.length > 0;
  }

  handleSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });

    try {
      let tmpPassword = uuid();
      let signupParams = {
        username: this.state.inviteEmail,
        password: tmpPassword,
        clientMetadata: {
          tempPw: tmpPassword,
        },
      };
      await Auth.signUp(signupParams).then(result => {
        this.props.history.push("/home");
      });
    } catch (e) {
      if (e.code === "UsernameExistsException") {
        await Auth.resendSignUp(this.state.email);
      }
      console.error(e);
      this.setState({ isLoading: false });
    }
  };

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  render() {
    return (
      <MainGrid>
        <ContentGrid>
          <ContentGridRow>
            <h2>👩‍🚀Control Room</h2>
          </ContentGridRow>
          <form onSubmit={this.handleSubmit}>
            <ContentGridRow>
              <div className="form-grid">
                <div className="form-lockup">
                  <label>Email to invite</label>
                  <input
                    autoFocus
                    id="inviteEmail"
                    type="text"
                    value={this.state.inviteEmail}
                    onChange={this.handleChange}
                  />
                </div>
                <LoaderButton
                  className="edit-task-button"
                  disabled={!this.validateForm()}
                  isLoading={this.state.isLoading}
                  text="Invite user"
                  loadingText="Inviting user..."
                />
              </div>
            </ContentGridRow>
          </form>
        </ContentGrid>
      </MainGrid>
    );
  }
}
