import React, { Component } from "react";
import { Auth } from "aws-amplify";
import Navigation from "../components/Navigation";

export default class Logout extends Component {
  componentDidMount() {
    this.logout();
  }

  logout = async () => {
    try {
      await Auth.signOut();
      this.props.userHasAuthenticated(false);
    } catch (e) {
      // do nothing
    }
  };

  render() {
    return (
      <React.Fragment>
        <Navigation authenticated={false} />
        <h1>Logged out</h1>
      </React.Fragment>
    );
  }
}
