import React, { Component } from "react";
import { Link } from "react-router-dom";
import logoImg from "./ws-logo.svg";
import "./style.scss";

export default class Navigation extends Component {
  render() {
    return (
      <div className="c-navigation">
        <div className="c-navigation_logo">
          <Link to="/home">
            <img src={logoImg} alt="logo" className="c-navigation_svg" />
          </Link>
        </div>
        <div className="c-navigation_links">
          <div className="c-navigation_link">
            <div className="c-navigation_link-text">The Week</div>
            <div className="c-navigation_purple-bar" />
          </div>
          <div className="c-navigation_avatar">
            <div className="c-navigation_picture" />
            <div className="c-navigation_purple-bar" />
          </div>
        </div>
      </div>
    );
  }
}
