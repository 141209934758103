import React from "react";
import MainGrid from "../../layout/Main/MainGrid";
import ContentGrid from "../../layout/Content/ContentGrid";
import ContentGridRow from "../../layout/Content/ContentGridRow";
import LoaderButton from "../../components/LoaderButton";
import "./style.scss";
import { API } from "aws-amplify";

export default class NewTask extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      taskName: "",
      taskDescription: "",
      taskId: 0
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    if (!this.props.isAuthenticated) {
      this.props.history.push("/home");
    }
  }

  validateForm() {
    return this.state.taskName.length > 0;
  }

  handleChange = event => {
    this.setState({
      [event.target.id]: event.target.value
    });
  };

  handleSubmit = async event => {
    event.preventDefault();

    this.setState({ isLoading: true });

    try {
      let bodyParams = {
        taskName: this.state.taskName,
        taskDescription:
          this.state.taskDescription.trim().length === 0
            ? " "
            : this.state.taskDescription
      };

      API.post("stack", "/task", {
        body: bodyParams
      }).then(result => {
        this.props.history.push("/home");
      });
    } catch (e) {
      this.setState({ isLoading: false });
    }
  };

  render() {
    return (
      <MainGrid>
        <ContentGrid>
          <ContentGridRow>
            <h2>New task</h2>
          </ContentGridRow>
          <form onSubmit={this.handleSubmit}>
            <ContentGridRow>
              <div className="form-grid">
                <div className="form-lockup">
                  <label>Task name</label>
                  <input
                    autoFocus
                    id="taskName"
                    type="text"
                    value={this.state.taskName}
                    onChange={this.handleChange}
                  />
                </div>
                <div className="form-lockup">
                  <label>Task description</label>
                  <input
                    id="taskDescription"
                    value={this.state.taskDescription}
                    onChange={this.handleChange}
                    type="text"
                  />
                </div>
                <LoaderButton
                  className="new-task-button"
                  disabled={!this.validateForm()}
                  isLoading={this.state.isLoading}
                  text="Add Task"
                  loadingText="Creating task..."
                />
              </div>
            </ContentGridRow>
          </form>
        </ContentGrid>
      </MainGrid>
    );
  }
}
