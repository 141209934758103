import React, { Component } from "react";
import { Draggable } from "react-beautiful-dnd";
import "./style.scss";

export default class TaskRO extends Component {
  constructor(props) {
    super(props);

    this.state = {
      first: false,
      second: false,
      third: false,
      finishing: false,
      finished: false,
      deleting: false
    };

    this.handleClick = this.handleClick.bind(this);
    this.finishTask = this.finishTask.bind(this);
  }

  async componentDidMount() {
    if (this.props.position === 0) {
      this.setState({ first: true });
    } else if (this.props.position === 1) {
      this.setState({ second: true });
    } else if (this.props.position === 2) {
      this.setState({ third: true });
    }
  }

  // change this function name to removeTask
  handleClick = () => {
    this.setState({ deleting: true });
    this.props.removeTask(this.props.taskId);
  };

  finishTask = (event) => {
    this.setState({ finishing: true });
    this.props.finishTask(this.props.taskId);
  }

  moveTaskUp = () => {
    let task = {
      taskId: this.props.taskId,
      position: this.props.position
    }
    
    this.props.moveTaskUp(task);
  }

  moveTaskDown = () => {
    let task = {
      taskId: this.props.taskId,
      position: this.props.position
    }
    
    this.props.moveTaskDown(task);
  }

  taskRemove = (finished) => {
    if (finished) {
      this.setState({ finishing: false, finished: true });
    } else {
      this.setState({ deleting: false, deleted: true });
    }
    this.props.refreshList(this.props.taskId);
  }

  render() {
    let taskClasses = "c-task";

    if (this.state.first) {
      taskClasses += " c-task__primary"
    }

    if (this.state.second) {
      taskClasses += " c-task__secondary"
    }

    if (this.state.finishing) {
      taskClasses += " c-task-finishing";
    }

    if (this.state.finished) {
      taskClasses += " c-task-finished";
    }

    if (this.state.deleting) {
      taskClasses += " c-task-deleting";
    }

    if (this.state.finished) {
      taskClasses += " c-task-deleted";
    }

    return (

      <Draggable
        draggableId={this.props.taskId}
        index={parseInt(this.props.position)}
        key={this.props.taskId}
      >
        {provided => (
          <React.Fragment>
            <div
              id={this.props.taskId}
              {...provided.draggableProps}
              ref={provided.innerRef}
              className={taskClasses}
              onAnimationEnd={() => {
                if (this.state.finishing) {
                  this.taskRemove(true);
                } else {
                  this.taskRemove(false);
                }
              }}
            >
              <div className="c-task__container">
                <div
                  className="c-task__draggable-container"
                  {...provided.dragHandleProps}
                  onClick={this.props.editTask}
                >
                  <div
                    className="c-task__drag-handle"
                  >
                    <div className="c-task__drag-handle-bars">
                      <div className="c-task__drag-handle-bar"></div>
                      <div className="c-task__drag-handle-bar"></div>
                      <div className="c-task__drag-handle-bar"></div>
                    </div>
                  </div>
                  <TaskTitle
                    finishing={this.state.finishing}
                    deleting={this.state.deleting}
                  >
                    {this.props.name}
                  </TaskTitle>
                </div>
                <div className="c-task__buttons">
                  <div className="c-task__button">
                    <div className="c-task__icon-container">
                      <svg
                        className="c-task__icon"
                        onClick={this.handleClick}
                        alt="delete task"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <g className="c-task__icon-svg">
                        <path d="M6.525 21C6.125 21 5.775 20.85 5.475 20.55C5.175 20.25 5.025 19.9 5.025 19.5V5.25H4.75C4.53333 5.25 4.35417 5.17917 4.2125 5.0375C4.07083 4.89583 4 4.71667 4 4.5C4 4.28333 4.07083 4.10417 4.2125 3.9625C4.35417 3.82083 4.53333 3.75 4.75 3.75H8.7C8.7 3.53333 8.77083 3.35417 8.9125 3.2125C9.05417 3.07083 9.23333 3 9.45 3H14.55C14.7667 3 14.9458 3.07083 15.0875 3.2125C15.2292 3.35417 15.3 3.53333 15.3 3.75H19.25C19.4667 3.75 19.6458 3.82083 19.7875 3.9625C19.9292 4.10417 20 4.28333 20 4.5C20 4.71667 19.9292 4.89583 19.7875 5.0375C19.6458 5.17917 19.4667 5.25 19.25 5.25H18.975V19.5C18.975 19.9 18.825 20.25 18.525 20.55C18.225 20.85 17.875 21 17.475 21H6.525ZM6.525 5.25V19.5H17.475V5.25H6.525ZM9.175 16.6C9.175 16.8167 9.24583 16.9958 9.3875 17.1375C9.52917 17.2792 9.70833 17.35 9.925 17.35C10.1417 17.35 10.3208 17.2792 10.4625 17.1375C10.6042 16.9958 10.675 16.8167 10.675 16.6V8.125C10.675 7.90833 10.6042 7.72917 10.4625 7.5875C10.3208 7.44583 10.1417 7.375 9.925 7.375C9.70833 7.375 9.52917 7.44583 9.3875 7.5875C9.24583 7.72917 9.175 7.90833 9.175 8.125V16.6ZM13.325 16.6C13.325 16.8167 13.3958 16.9958 13.5375 17.1375C13.6792 17.2792 13.8583 17.35 14.075 17.35C14.2917 17.35 14.4708 17.2792 14.6125 17.1375C14.7542 16.9958 14.825 16.8167 14.825 16.6V8.125C14.825 7.90833 14.7542 7.72917 14.6125 7.5875C14.4708 7.44583 14.2917 7.375 14.075 7.375C13.8583 7.375 13.6792 7.44583 13.5375 7.5875C13.3958 7.72917 13.325 7.90833 13.325 8.125V16.6ZM6.525 5.25V19.5V5.25V5.25Z" fill="black"/>
                        </g>
                      </svg>
                    </div>
                  </div>
                  <div className="c-task__button">
                    <div className="c-task__icon-container">
                      <svg
                        className="c-task__icon"
                        onClick={this.props.moveTaskState}
                        alt="move position"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <g className="c-task__icon-svg">
                        <path d="M3 17.5V6.5C3 6.08333 3.14583 5.72917 3.4375 5.4375C3.72917 5.14583 4.08333 5 4.5 5H19.5C19.9167 5 20.2708 5.14583 20.5625 5.4375C20.8542 5.72917 21 6.08333 21 6.5V17.5C21 17.9167 20.8542 18.2708 20.5625 18.5625C20.2708 18.8542 19.9167 19 19.5 19H4.5C4.08333 19 3.72917 18.8542 3.4375 18.5625C3.14583 18.2708 3 17.9167 3 17.5ZM4.5 9.175H7.15V6.5H4.5V9.175ZM8.65 9.175H19.5V6.5H8.65V9.175ZM8.65 13.325H19.5V10.675H8.65V13.325ZM8.65 17.5H19.5V14.825H8.65V17.5ZM4.5 17.5H7.15V14.825H4.5V17.5ZM4.5 13.325H7.15V10.675H4.5V13.325Z" fill="black"/>
                        </g>
                      </svg>
                    </div>
                  </div>
                  <div className="c-task__button">
                    <div className="c-task__icon-container">
                      <svg
                        className="c-task__icon"
                        onClick={this.moveTaskDown}
                        alt="send to bottom"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <g className="c-task__icon-svg">
                        <path d="M17.375 7.375L12.6 12.15C12.5167 12.2333 12.4333 12.2917 12.35 12.325C12.2667 12.3583 12.175 12.375 12.075 12.375C11.975 12.375 11.8833 12.3583 11.8 12.325C11.7167 12.2917 11.6333 12.2333 11.55 12.15L6.75 7.35C6.6 7.2 6.525 7.02083 6.525 6.8125C6.525 6.60417 6.6 6.425 6.75 6.275C6.9 6.125 7.07917 6.05 7.2875 6.05C7.49583 6.05 7.675 6.125 7.825 6.275L12.075 10.525L16.325 6.275C16.475 6.125 16.65 6.05 16.85 6.05C17.05 6.05 17.225 6.125 17.375 6.275C17.525 6.425 17.6 6.60833 17.6 6.825C17.6 7.04167 17.525 7.225 17.375 7.375V7.375ZM18 17.25C18 17.4667 17.9292 17.6458 17.7875 17.7875C17.6458 17.9292 17.4667 18 17.25 18L6.75 18C6.53333 18 6.35417 17.9292 6.2125 17.7875C6.07083 17.6458 6 17.4667 6 17.25C6 17.0333 6.07083 16.8542 6.2125 16.7125C6.35417 16.5708 6.53333 16.5 6.75 16.5L17.25 16.5C17.4667 16.5 17.6458 16.5708 17.7875 16.7125C17.9292 16.8542 18 17.0333 18 17.25Z" fill="black"/>
                        </g>
                      </svg>
                    </div>
                  </div>
                  <div className="c-task__button">
                    <div className="c-task__icon-container">
                      <svg
                        className="c-task__icon"
                        onClick={this.moveTaskUp}
                        alt="send to top"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <g className="c-task__icon-svg">
                        <path d="M17.325 17.725C17.175 17.875 16.9958 17.95 16.7875 17.95C16.5792 17.95 16.4 17.875 16.25 17.725L12 13.475L7.725 17.75C7.575 17.9 7.39583 17.9708 7.1875 17.9625C6.97917 17.9542 6.8 17.875 6.65 17.725C6.5 17.575 6.425 17.4 6.425 17.2C6.425 17 6.5 16.825 6.65 16.675L11.475 11.85C11.5583 11.7667 11.6417 11.7083 11.725 11.675C11.8083 11.6417 11.9 11.625 12 11.625C12.1 11.625 12.1917 11.6417 12.275 11.675C12.3583 11.7083 12.4417 11.7667 12.525 11.85L17.325 16.65C17.475 16.8 17.55 16.9792 17.55 17.1875C17.55 17.3958 17.475 17.575 17.325 17.725V17.725ZM18 6.75C18 6.96667 17.9292 7.14583 17.7875 7.2875C17.6458 7.42917 17.4667 7.5 17.25 7.5L6.75 7.5C6.53333 7.5 6.35417 7.42917 6.2125 7.2875C6.07083 7.14583 6 6.96667 6 6.75C6 6.53333 6.07083 6.35417 6.2125 6.2125C6.35417 6.07083 6.53333 6 6.75 6L17.25 6C17.4667 6 17.6458 6.07083 17.7875 6.2125C17.9292 6.35417 18 6.53333 18 6.75Z" fill="black"/>
                        </g>
                      </svg>
                    </div>
                  </div>
                  <div className="c-task__finish-button">
                    <button onClick={this.finishTask}>FINISH IT</button>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        )}
      </Draggable>
    );
  }
}

function TaskTitle(props) {
  if (props.finishing) {
    return (
      <h6 className="c-task__title-finishing" onClick={props.viewDetails}>FINISHING</h6>
    );
  }
  if (props.deleting) {
    return (
      <h6 className="c-task__title-deleting" onClick={props.viewDetails}>DELETING</h6>
    );
  }
  return (
    <h6 className="c-task__title" onClick={props.viewDetails}>{props.children}</h6>
  );
}