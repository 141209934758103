import React from "react";
import ContentGrid from "../../layout/Content/ContentGrid";
import ContentGridRow from "../../layout/Content/ContentGridRow";
import MainGrid from "../../layout/Main/MainGrid";

export default class HowItWorks extends React.Component {
  render() {
    window.scrollTo(0, 0);

    return (
      <MainGrid>
        <ContentGrid>
          <ContentGridRow>
            <h1>How Weekstack Works</h1>
          </ContentGridRow>
          <ContentGridRow>
            <p>
              One of the biggest reasons people do not accomplish what they want
              to accomplish in a week is because of one thing
            </p>
            <h2>
              People only think about their goal, and forget about their
              obligations
            </h2>
          </ContentGridRow>
          <ContentGridRow>
            <blockquote>
              "This week I am going to deliver that presentation on business
              strategy that I am on the hook for. A whole week should be plenty
              of time." - Anyone
            </blockquote>
          </ContentGridRow>
          <ContentGridRow>
            <p>
              You get to work on Monday, do your stand-up meeting, chat with
              Jane about a thing and then head to your next meeting and then
              it's lunch and then you respond to emails for an hour, then the
              boss asks you for something and now it's 4 PM{" "}
              <span role="img" aria-label="screaming face">
                😱
              </span>
            </p>
          </ContentGridRow>
          <ContentGridRow>
            <p>
              While we might be awake for 16 hours a day, and might be at work
              for 40 hours a week...you probably have far less time to do the
              things you want to do than you know.
            </p>
            <h2>WeekStack is not here to magically "make more time"</h2>
            <p>
              Making more time for most of us is unrealistic, or just too much
              sacrafice.
            </p>
          </ContentGridRow>
          <ContentGridRow>
            <h2>You can be insanely productive, without going insane</h2>
            <p>
              Warren Buffett famously has{" "}
              <a href="https://www.cnbc.com/2018/09/07/warren-buffett-taught-bill-gates-about-time-management-by-sharing-his-blank-calendar.html">
                nothing on his calendar
              </a>
            </p>
            <p>
              Same with{" "}
              <a href="https://m.signalvnoise.com/what-my-calendar-looks-like/#---0-83.elhr9pcaf">
                Jason Fried of Basecamp
              </a>
            </p>
            <p>
              Nobody would argue that these two people are among some of the
              most successful people in the world.
            </p>
          </ContentGridRow>
          <ContentGridRow>
            <p>
              You might argue that having a blank calendar is unrealistic for
              you, and that we understand.
            </p>
            <h2>WeekStack is about being realistic with your time</h2>
          </ContentGridRow>
        </ContentGrid>
      </MainGrid>
    );
  }
}
