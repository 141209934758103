import { API } from "aws-amplify";

export const moveTask = async (task, newPosition) => {
  try {
    // call API to update the tasks order
    let moveParams = {
      taskId: task.taskId,
      newPosition: newPosition
    };

    await API.post("stack", "/moveTask", {
      body: moveParams
    });

    let bodyParams = {
      taskId: task.taskId,
      taskName: task.taskName,
      taskDescription:
        task.taskDescription.trim().length === 0
          ? " "
          : task.taskDescription
    };

    await API.post("stack", "/updateTask", {
      body: bodyParams
    });
  } catch (e) {
    return e;
  }
}