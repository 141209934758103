import React, { Component } from "react";
import TaskRO from "./TaskRO";
import TaskMove from "./TaskMove";

export default class Task extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editTask: props.editTask,
      moveTask: false
    };
  }

  editTask = taskId => {
    this.props.editTask(taskId);
  };

  finishEdit = event => {
    this.setState({ editTask: false });
  };

  finishTask = () => {
    this.props.finishTask(this.props.taskId);
  }

  removeTask = () => {
    this.props.removeTask(this.props.taskId);
  };

  moveTaskUp = (task) => {
    this.props.moveTaskUp(task);
  }

  moveTaskDown = (task) => {
    this.props.moveTaskDown(task);
  }

  moveTaskPosition = (task, newPosition) => {
    this.props.moveTaskPosition(task, newPosition);
  }

  moveTaskState = (event) => {
    this.setState({ moveTask: true });
  }

  standardTaskState = (event) => {
    this.setState({ moveTask: false });
  }

  refreshList = taskId => {
    this.props.refreshList(taskId);
  }

  render() {
    if (this.state.moveTask) {
      return (
        <div>
          <TaskMove
            taskId={this.props.taskId}
            name={this.props.name}
            description={this.props.description}
            position={this.props.position}
            refreshList={() => { this.refreshList(this.props.taskId) }}
            standardTaskState={this.standardTaskState}
            tasksList={this.props.tasksList}
            moveTaskPosition={this.moveTaskPosition}
          />
        </div>
      );
    }
    return (
      <div>
        <TaskRO
          taskId={this.props.taskId}
          name={this.props.name}
          description={this.props.description}
          removeTask={this.removeTask}
          finishTask={this.finishTask}
          moveTaskUp={this.moveTaskUp}
          moveTaskDown={this.moveTaskDown}
          position={this.props.position}
          moveTaskState={this.moveTaskState}
          editTask={() => {
            this.editTask(this.props.taskId);
          }}
          refreshList={() => { this.refreshList(this.props.taskId) }}
        />
      </div>
    );
  }
}
